import React from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridColDef, GridToolbar, frFR } from '@material-ui/data-grid';
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import moment from "moment";

const Orders = (props) => {

  const {
    orders
  } = props

  /**
   * On retraite la réponse pour la rendre compatible au tableau
   */
  let responseFormat = [];
  if(orders.length > 0){
    responseFormat = orders.map((value, key) => {
      return {id: value.id, number_order: value.number_order, total_price_ttc: value.total_price_ttc, lastname: value.customer_info.lastname, firstname: value.customer_info.firstname, email: value.customer_info.email, delivery_date: value.delivery_info.delivery_date, multiple:'x'+value.multiple, delivery_type: value.delivery_info.type}
    })
  }

  const theme = createMuiTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    frFR,
  );

  /**
   * On définit les colonnes que l'on veut mapper au data
   */
  const columns: GridColDef[] = [
    { field: 'lastname', headerName: 'Nom', flex: 1, renderCell: (params: GridCellParams) => (
      <Capitalize>
        {params.value}
      </Capitalize>
    )},
    { field: 'firstname', headerName: 'Prénom', flex: 1, renderCell: (params: GridCellParams) => (
      <Capitalize>
        {params.value}
      </Capitalize>
    )},
    { field: 'email', headerName: 'Email', flex: 1, renderCell: (params: GridCellParams) => (
      <div>
        {params.value}
      </div>
    )},
    { field: 'delivery_type', headerName: 'Type', flex: 1, renderCell: (params: GridCellParams) => (
      <div>
        {params.value === 'shop' && "Boutique"}
        {params.value === 'spot' && "Charrette"}
      </div>
    )},
    { field: 'delivery_date', flex: 1, headerName: 'Date', renderCell: (params: GridCellParams) => (
      <div>
        {moment(params.value).format('DD/MM/YYYY')}
      </div>
    )},
    { field: 'number_order', flex: 1, headerName: 'Numéro de commande' },
    { field: 'multiple', flex: 1, headerName: 'Multiple du crédit' },
  ];

  return (
    <div style={{ height: 500, width: '100%' }} className="mb-2">
      {responseFormat != null &&
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={responseFormat}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={20}
          />
        </ThemeProvider>
      }
    </div>
  )

}

const Capitalize = styled.div`
  text-transform: capitalize;
`;

Orders.propTypes = {
  orders: PropTypes.array
};

Orders.defaultValues = {
  orders: []
};

export default Orders;
