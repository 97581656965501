import React from "react";
import styled from "@emotion/styled";
import { Modal, Col, Row } from "react-bootstrap";
import moment from "moment";
import Loader from "views/universal/Loader/Loader";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { centimesToCurrency } from "utils/utils";

const PopupAdvantage = (props) => {
  const { show, onHide, onEscapeKeyDown, customerAdvantage, isLoading } = props;

  require("moment/locale/fr.js");

  return (
    <Popup
      size="lg"
      show={show}
      onHide={onHide}
      onEscapeKeyDown={onEscapeKeyDown}
    >
      {isLoading ? (
        <Loader items={2} />
      ) : (
        <>
          {customerAdvantage ? (
            <>
              <Row>
                <Col>
                  <Title className="text-center">
                    <small>
                      Avantages de{" "}
                      {customerAdvantage.firstname &&
                        customerAdvantage.firstname}{" "}
                      {customerAdvantage &&
                        customerAdvantage.lastname &&
                        customerAdvantage.lastname}
                    </small>
                    <br />
                    <span>
                      {centimesToCurrency(
                        customerAdvantage.availableAdvantageAmount
                      )}
                    </span>
                  </Title>
                </Col>
              </Row>
              <Header noGutters>
                <Col sm={1}>
                  <p>
                    <b>Type</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Avantage</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Date commande</b>
                  </p>
                </Col>
                <Col sm={3}>
                  <p>
                    <b>Date d'expiration/parrainé</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <p>
                    <b>Montant</b>
                  </p>
                </Col>
                <Col sm={2}>
                  <Tippy
                    placement="left"
                    content={
                      <span>
                        Fiche prod de la commande qui a généré le cagnottage
                      </span>
                    }
                  >
                    <p>
                      <b>Fiche prod</b>{" "}
                      <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                    </p>
                  </Tippy>
                </Col>
              </Header>

              {customerAdvantage.advantages.map((a) => (
                <Operation key={a} type={a.type} noGutters>
                  <Col sm={1}>
                    <p>{a.type === "debit" ? <b>Débit</b> : <b>Crédit</b>}</p>
                  </Col>
                  <Col sm={2}>
                    <p>
                      {a.advantageName === "percentOrder" ? 
                        <>Gourmand</>
                       : a.advantageName === "sponsorship" ? 
                        <>Parrainage</>
                       : a.advantageName === "percentEarlyOrder" ? 
                       <>Anticipé</>
                       : a.advantageName === "firstOrder" ? 
                       <>Première commande</>
                       : a.advantageName === "sav" ? 
                       <>SAV</>
                       : null}
                    </p>
                  </Col>
                  <Col sm={2}>
                    <p>
                      <b>
                        {a.date &&
                          moment(a.date).format("DD/MM/YYYY")}
                      </b>
                    </p>
                  </Col>
                  <Col sm={3}>
                    <p>
                      <b>
                        {a.expirationDate &&
                          moment(a.expirationDate).format("DD/MM/YYYY")}
                        {a.sponsoredName && a.sponsoredName}
                      </b>
                    </p>
                  </Col>
                  <Col sm={2}>
                    <p>
                      <b>
                        {a.amount &&
                        a.type === "debit" &&
                        a.advantageName !== "sponsorship"
                          ? centimesToCurrency(-1 * a.amount)
                          : a.amount &&
                            a.type === "credit" &&
                            a.advantageName !== "sponsorship"
                          ? centimesToCurrency(a.amount)
                          : null}
                      </b>
                    </p>
                  </Col>
                  <Col sm={2}>
                    {" "}
                    {a.prodPageLink &&
                      <p>
                        <Link href={a.prodPageLink} target="_blank">
                          Fiche prod
                        </Link>
                      </p>
                    }
                  </Col>
                </Operation>
              ))}
            </>
          ) : (
            "Pas d'avantage pour ce client."
          )}
        </>
      )}
    </Popup>
  );
};

const Popup = styled(Modal)`
  .modal-content {
    background-color: #ecf0f5 !important;
  }
  p {
    margin-bottom: 0rem;
  }
`;

const Title = styled.h3`
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.green};
  }
`;

const Header = styled(Row)`
  color: grey;
  padding: 0.6rem;
  font-size: 0.8rem;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.green};
  text-decoration: underline;
  text-align: right;
`;

const Operation = styled(Row)`
  padding: 0.6rem;
  background-color: white;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  p{
    color: ${(props) => props.type === "credit" ? props.theme.colors.green : props.type === "debit" ? props.theme.colors.grey : props.theme.colors.black};
  }
`;

PopupAdvantage.propTypes = {};

export default PopupAdvantage;
