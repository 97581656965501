import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Form } from "react-bootstrap";
import Card from "views/universal/Components/Card";
import {
  faTicketAlt
} from "@fortawesome/free-solid-svg-icons"
import CurrencyInput from 'react-currency-input-field';

const Variables = (props) => {

  const { amount, note, handleChange } = props;

  return (
    <Row className="mb-2">
      <Col xs={12} sm={6} md={3}>
        <Card title="Créditer la cagnotte de ses clients de" description="" icon={faTicketAlt} colorIcon={'#abd156'}>
          <div className="mb-2">
            <CurrencyInput
              placeholder="Montant"
              className="form-control"
              defaultValue={Number.isNaN(amount) ? 0 : amount}
              decimalSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              allowNegativeValue={false}
              intlConfig={{"locale":"fr-FR","currency":"EUR"}}
              onValueChange={(value, name) => handleChange('amount', parseFloat(value))}
            />
          </div>
          <Form.Control
            type="text"
            defaultValue={note}
            onChange={(e) => handleChange('note', e.target.value)}
            placeholder="Motif de crédit"
          />
        </Card>
      </Col>
    </Row>
  );
};

Variables.propTypes = {
  amount: PropTypes.number,
  note: PropTypes.string,
  handleChange: PropTypes.func
};

export default Variables;
