import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Loader from "views/universal/Loader/Loader";
import { Col, Row } from "react-bootstrap";
import swal from 'sweetalert';
import moment from "moment";
import { Stepper, Step, StepLabel, StepContent, Paper, CircularProgress, Button } from '@material-ui/core';
import { savOperations } from "state/ducks/store/sav";
import { orderOperations } from "state/ducks/store/order";
import Filters from "./Filters/Filters";
import Variables from "./Variables";
import Orders from "./Orders"
import TypeFilter from "./Filters/TypeFilter"
import ResponseCard from "../Common/ResponseCard";

const CreditPool = (props) => {

  const savPage = useSelector(
    (state) => state.store.sav.admin,
    shallowEqual
  );

  const [requestParams, setRequestParams] = useState({
    rangeDates : {start: moment(), end: moment()},
    amount     : 0,
    note       : '',
  });

  const [filterSelected, setFilterSelected] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse]   = useState(null); // {'total', 'success', 'fails'}
  // Permet de gérer l'état des étapes
  const [activeStep, setActiveStep] = useState(0);

  const dispatch = useDispatch();

  /**
   * Gère le changement des variables
   */
  const handleFiltersChange = (filters) => {
    setFilterSelected(filters);
  }

  /**
   * Gère le changement des variables
   */
  const handleVariablesChange = (attr, value) => {
    setRequestParams({
      ...requestParams,
      [attr]:value
    });
  }

  const requestParamsOk = () => {
    if (requestParams.amount <= 0 || requestParams.note === '') {
      return false;
    }
    return true;
  }

  /**
   * Récupère les commandes
   */
  const getOrders = () => {
    setIsLoading(true);

    let params = {...requestParams}
    params = {
      ...params,
      deliveryType  : filterSelected.deliveryType && filterSelected.deliveryType.value,
      startDate     : params.rangeDates && params.rangeDates.start && moment(params.rangeDates.start).format("YYYY-MM-DD"),
      endDate       : params.rangeDates && params.rangeDates.end && moment(params.rangeDates.end).format("YYYY-MM-DD"),
      customers     : filterSelected.customers && filterSelected.customers.map((value) => value.value),
      vehicles      : filterSelected.vehicles && filterSelected.vehicles.map((value)   => value.value),
      spots         : filterSelected.spots && filterSelected.spots.map((value)         => value.value),
      establishment : filterSelected.establishment && filterSelected.establishment.value,
      products : filterSelected.products && filterSelected.products.filter((value) => {
        let type = value.value.split('#')
        return (type.length === 2 && type[1] === 'product');
      }).map((value) => parseInt(value.value.split('#')[0])),
      ingredients : filterSelected.products && filterSelected.products.filter((value) => {
        let type = value.value.split('#')
        return (type.length === 2 && type[1] === 'ingredient');
      }).map((value) => parseInt(value.value.split('#')[0])),
      groups: ['order_info', 'order_multiple']
    };

    return dispatch(orderOperations.getOrders(params))
    .then(
      (result) => {
        setIsLoading(false);
        if (result.data && result.data.result) {
          setResponse(result.data.result);
          return true;
        }
        return false;
      },
      (error) => {
        setIsLoading(false);
        if (error.data && error.data.result) {
          setResponse(error.data.result);
        }
        return false;
      }
    )
  }

  /**
   * Crédite les porte-monnaie numérique (nommé anciennement cagnotte)
   */
  const creditPools = async () => {
    if (!requestParamsOk()) {
      swal({
        title: "Pas si vite !",
        text: "Merci de renseigner un montant et un motif",
        icon: "warning",
        buttons: {
          ok: "Ok",
        },
      })
      return false;
    }
    let popupTxt = (typeFilter === 'order') ? "Etes-vous sûr de vouloir créditer de "+ requestParams.amount + "€ les cagnottes clients liées à ces commandes ?" : "Etes-vous sûr de vouloir créditer de "+ requestParams.amount + "€ les cagnottes de ces clients ?";
    return swal({
      title: "Créditer les cagnottes ?",
      text: popupTxt,
      icon: "warning",
      buttons: {
        cancel: "Annuler",
        confirm: "Valider",
      },
    })
    .then((confirm) => {
      if (confirm === true) {
        setIsLoading(true);
        let params = {...requestParams}
        if (typeFilter === 'order') {
          params = {
            ...params,
            deliveryType  : filterSelected.deliveryType && filterSelected.deliveryType.value,
            startDate     : params.rangeDates && params.rangeDates.start && moment(params.rangeDates.start).format("YYYY-MM-DD"),
            endDate       : params.rangeDates && params.rangeDates.end && moment(params.rangeDates.end).format("YYYY-MM-DD"),
            amount        : parseInt(params.amount * 100),
            customers     : filterSelected.customers && filterSelected.customers.map((value) => value.value),
            vehicles      : filterSelected.vehicles && filterSelected.vehicles.map((value) => value.value),
            spots         : filterSelected.spots && filterSelected.spots.map((value) => value.value),
            establishment : filterSelected.establishment && filterSelected.establishment.value,
            products : filterSelected.products && filterSelected.products.filter((value) => {
              let type = value.value.split('#')
              return (type.length === 2 && type[1] === 'product');
            }).map((value) => parseInt(value.value.split('#')[0])),
            ingredients : filterSelected.products && filterSelected.products.filter((value) => {
              let type = value.value.split('#')
              return (type.length === 2 && type[1] === 'ingredient');
            }).map((value) => parseInt(value.value.split('#')[0])),
            groups: ['order_info', 'order_multiple']
          };
        }
        else if (typeFilter === 'customer') {
          params = {
            ...params,
            customerIds: filterSelected.customers && filterSelected.customers.map((value) => value.value),
          };
          params = {
            ...params,
            amount: parseInt(params.amount * 100),
          };
        }

        return dispatch(savOperations.creditPools(typeFilter, params))
        .then(
          (result) => {
            setIsLoading(false);
            if (result.data && result.data.result) {
              setResponse(result.data.result);
            }
            return true;
          },
          (error) => {
            setIsLoading(false);
            if (error.data && error.data.result) {
              setResponse(error.data.result);
            }
            return false;
          }
        )
      }
    });
  }

  /**
   * Gère l'état du type de recherche voulu
   */
  const [typeFilter, setTypeFilter]   = useState('order');
  const handleTypeFilterChange = (value) => {
    setTypeFilter(value);
  }

  /**
   * Permet de définir les labels des Steppers
   */
  const getSteps = () => {
    return ['Choisir le type de recherche', 'Faite une recherche par commande ou client', 'Vérifier la liste des commandes', 'Définir le montant du cagnottage et son motif'];
  }

  /**
   * Permet de définir les composants utilisés pour telle Step
   */
  const getStepContent = (step) => {
    let comp;
    switch (step) {
      case 0:
        comp = <TypeFilter value={typeFilter} handleChange={handleTypeFilterChange}/>;
        break;
      case 1:
        comp = <Filters
          typeFilter={typeFilter}
          handleChange={handleFiltersChange}
          vehicles={savPage.vehicles}
          spots={savPage.spots}
          establishments={savPage.establishments}
          products={savPage.products}
          customers={savPage.customers}
          handleDateChange={handleVariablesChange}
          rangeDates={requestParams.rangeDates}
          />
        ;
        break;
      case 2:
        comp = <Orders orders={(response && response.orders) ? response.orders : []}/>;
        break;
      case 3:
        comp = <Variables
          handleChange={handleVariablesChange}
          amount={requestParams.amount}
          note={''}
          />;
          break;
      default:
        comp = 'Etape inconnu';
        break;
    }
    return comp;
  }

  /**
   * Permet de définir quelle fonction est utilisée lors du passage à l'étape suivante
   */
  const getStepFunction = async (step) => {
    switch (step) {
      case 0:
        handleNext();
        break;
      case 1:
        if (typeFilter === 'order') {
          await getOrders();
        }
        handleNext();
        break;
      case 2:
        handleNext();
        break;
      case 3:
        let credit = await creditPools()
        if (credit) {
          handleNext();
        }
        break;
      default:
        break;
    }
  }

  const steps = getSteps();

  const handleNext = () => {
    let plus = 1;
    if (typeFilter === 'customer') {
      if (activeStep + 1 === 2) {
        plus = 2;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + plus);
  };

  const handleBack = () => {
    let minus = 1;
    if (typeFilter === 'customer') {
      if (activeStep - 1 === 2) {
        minus = 2;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - minus);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Si le bouton suivant doit être désactivé
  const isNextDisable = () => {
    if (activeStep === 1) {
      if (requestParams.rangeDates.start === null || requestParams.rangeDates.end === null) {
        return true;
      }
      // Maximum 30 jours
      if (requestParams.rangeDates.start && requestParams.rangeDates.end) {
          let diff = moment(requestParams.rangeDates.start).diff(requestParams.rangeDates.end, 'days');
          if (diff > 30) {
            return false;
          }
      }
    }
    if (activeStep === 2 && (response === null || response.orders === undefined || response?.orders.length === 0)) {
      return true;
    }
    if (typeFilter === 'customer') {
      if (activeStep === 1) {
        if (!filterSelected?.customers || filterSelected.customers.length <= 0) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <>
      {savPage.isLoading ? (
        <Loader items={2} />
      ) : (
        <>
          <div className="mt-3">
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {getStepContent(index)}
                    <Row>
                      <Col xs={12}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Retour
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isNextDisable() || isLoading}
                          onClick={(e) => getStepFunction(index)}
                        >
                          {isLoading
                            ? <CircularProgress color="inherit" size={18} />
                            : activeStep === steps.length - 1 ? 'Créditer ces clients' : 'Suivant'
                          }
                        </Button>
                      </Col>
                    </Row>
                  </StepContent>
                </Step>
              ))}
              {activeStep === steps.length && (
                <Paper square elevation={0}>
                  <Row className="mt-3">
                    <Col xs={4}>
                      <div className="mt-3">
                        <Button onClick={handleReset} variant="outlined" color="primary">
                          Faire un autre crédit ?
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Paper>
              )}
              <ResponseCard title={"Résultats de la dernière requête"} success={response?.success} fails={response?.fails}/>
            </Stepper>
          </div>
        </>
      )}
    </>
  );
};

CreditPool.propTypes = {};

CreditPool.defaultProps = {};

export default CreditPool;
